export const About = (props) => {
  return (
    <div id="aboutMe" className='text-center'>
      <div className="container"  >
        <div className="section-title ">
          <div className="row text-center">
            <h2>O mně</h2>
            <h3>"Pohyb a změna je život."</h3>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="about-text">

                <div className="col-xs-12" style={{}}>
                  <img src="img/about.jpg" style={{ borderRadius: "50%", width: 200, height: 200, display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: 40, marginTop: 40 }} alt="Avatar" />
                </div>
                
                <div className="col-xs-12" style={{}}>
                  {props.data ? props.data.paragraph.map((d,i) => (
                    <p className='text-left' style={{marginBottom: 20}} key={i}>{d}</p>
                  )) : "loading..."}
                </div>
                  </div>
            </div>
          </div>
          <div className="row">
            <div className="list-style text-left" style={{ marginLeft: "auto", marginRight: "auto" }}>
              <div className="col-xs-2" />
              <div className="col-xs-8" >
                <h4 style={{marginBottom: 20, marginTop: 40 }}>A jaké kuzy, workshopy a akce to jsou?</h4>
                <ul>
                  {props.data
                    ? props.data.Why.map((d, i) => (
                      <li style={{marginBottom: 10}} key={i}><img src="img/point.png"></img>{d}</li>
                    ))
                    : "loading"}
                </ul>
              </div>
            </div>
            <div className="col-xs-2" />
          </div>
        </div>
      </div>
    </div>
  );
};