export const Intro = (props) => {
  return (
      <div id="intro" className='text-center' style={{ marginTop : 30 }}>
        <div className="container"  >
          <div className="section-title ">
            <div className="row text-center">
              <h2>Úvod</h2>
            </div>

            <div className="row text-center">
              <div className="list-style text-left" style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="col-xs-2" />
                <div className="col-xs-8" >
                  <h3 style={{ marginBottom: 20 }}> S čím přicházíte a čím se zabývám?</h3>
                  <ul>
                    {props.data
                      ? props.data.Issues.map((d, i) => (
                        <li style={{ marginBottom: 10 }} key={i}><img src="img/point.png"></img>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
              </div>
              <div className="col-xs-2" />
            </div>

            <div className="row text-center">
              <div className="list-style text-left" style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="col-xs-2" />
                <div className="col-xs-8" >
                  <h3 style={{ marginBottom: 20 }}> S čím Vám může kraniosakrální terapie pomoci?</h3>
                  <ul>
                    {props.data
                      ? props.data.KranyoTerapy.map((d, i) => (
                        <li style={{ marginBottom: 10 }} key={i}><img src="img/point.png"></img>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
              </div>
              <div className="col-xs-2" />
            </div>
          </div>
        </div>
      </div>
  );
};