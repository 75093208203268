export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Mé služby</h2>
          <div className='row'>
            {props.data
              ? props.data.Row_1.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='service-desc'>
                    <img src={d.img} className='img-circle' alt='' />
                    <h3 className='text-left'>{d.name}</h3>
                    {
                      d.text.map((k,j) => (
                        <p className='text-left' style={{ marginBottom: 30 , textIndent: 50 }}>{k}</p>
                      ))
                    }
                  </div>
                </div>
              ))
              : 'loading'}
          </div>
          <div className='row'>
            {props.data
              ? props.data.Row_2.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='service-desc'>
                    <img src={d.img} className='img-circle' alt='' />
                    <h3 className='text-left'>{d.name}</h3>
                    {
                      d.text.map((k,j) => (
                        <p className='text-left' style={{ marginBottom: 30 , textIndent: 50 }}>{ k }</p>
                      ))
                    }
                  </div>
                </div>
              ))
              : 'loading'}
          </div>
        </div>
      </div>
    </div>
  )
}