import MapSection from './map/Map' // import the map here

export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='section-title'>
            <div className='col-md-8'>
              <div className='row'>
                <h2>Jak mě kontaktovat</h2>
              </div>
              {/*<div>
                <MapSection location={location} zoomLevel={20} /> {}
              </div>*/
                <div>
                  <div className='contact-item'>
                    {/*<span>
                      <i className='fa fa-map-marker'></i> Adresa
                    </span>
                     <p href={props.data ? props.data.address.addressLink : '/'}>{props.data ? props.data.address.textAddress : 'loading'}</p> 
                    
                    <p>
                      {props.data ? <a style={{ color: "#ffffffbf" }} href={props.data.address.addressLink} target="_blank">{props.data.address.textAddress}</a> : 'loading'}
                    </p>*/}
                  </div>
                  <div className='contact-item'>
                    <span>
                      <i className='fa fa-phone'></i> Telefon
                    </span>
                    <p>
                      {props.data ? props.data.phone : 'loading'}
                    </p>
                  </div>
                  <div className='contact-item'>
                    <span>
                      <i className='fa fa-envelope-o'></i> Email
                    </span>
                    <p>
                      {props.data ? <a style={{ color: "#ffffffbf" }} href={"mailto:" + props.data.email}>{props.data.email}</a> : 'loading'}
                    </p>
                  </div>
                  <div className='contact-item'>
                    <span>IČO </span>
                    <p>
                      {props.data ? props.data.ico : 'loading'}
                    </p>
                  </div>
                </div>}
            </div>
          </div>

          <div className='col-md-12 text-center' style={{ marginTop: 30 }}>
            <p>Z důvodu pohodlí klientů a omezení administrativního času, prosím o kontakt primárně prostřednictvím e-mailu, nebo SMS</p>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <p>Facebook</p>
                    <a href={props.data ? props.data.facebook : '/'} target="_blank">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <p>Instagram</p>
                    <a href={props.data ? props.data.instagram : '/'} target="_blank">
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 JT
          </p>
        </div>
      </div>
    </div>
  )
}

/*
const location = (props) =>  ({
  address: props.data ? props.data.address.textAddress : 'loading',
  lat: props.data ? props.data.address.latitude : 'loading',
  lng: props.data ? props.data.address.longitude : 'loading',
}) // our location object from earlier
*/
const location = {
  address: "Roháčova 1095/77, Praha 3",
  lat: 50.08858060458003,
  lng: 14.462024729555253,
} // our location object from earlier
