export const WorkDetails = (props) => {
  return (
    <div id='workDetails' className='text-center section-title'>
      <div className='container'>
        <div className="about-text">
          <h2>Jak Pracuji</h2>
          <div className="row text-center">
            <div className="col-xs-1"/>
            <div className="col-xs-10" >
              <ol>
                {props.data
                  ? props.data.map((d, i) => (
                    <li style={{ marginBottom: 15, marginTop: 15 }} key={`${d}-${i}`}>
                      <div className="row">
                        <h3 className="col-xs-12" >{i+1+"."} </h3>
                      </div>
                      <div className="row">
                        <div className="col-xs-1"/>
                        <p className="col-xs-10 text-center">{d}</p>
                        <div className="col-xs-1"/>
                      </div>
                      <div className="row">
                        <img src="img/point.png"></img>
                      </div>
                    </li>
                  ))
                  : "loading..."}
              </ol>
            </div> 
            <div className="col-xs-1"/>
          </div> 
        </div>
      </div>
    </div>
  )
}