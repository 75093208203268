export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 col-xs-12 intro-text'>
                <a>
                  <div >
                    <img src="img/Logo.png" style={{ borderRadius: "50%", width: 200, height: 200, marginBottom: 30}} alt="logo" />
                  </div>
                </a>
                  <h2>Bc. Iva Ripperová</h2>
                  <h3>Fyzioterapeut</h3>  
                <a
                  href='#aboutMe'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  více
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}