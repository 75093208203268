export const PriceList = (props) => {
    return (
        <div id='priceList' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Ceník</h2>
                    <div className='row'>
                        {props.data
                            ? props.data.Row_1.map((d, i) => (
                                <div key={`${d.name}-${i}`} className='col-md-4'>
                                    <div className='Price-item'>
                                        <h3 className='text-center'>{d.header}</h3>
                                        <p className='text-center' style={{ marginBottom: 10 }}>{ d.price }</p>
                                        {
                                            d.desc.map((k,j) => (
                                                <p className='text-center' style={{ marginBottom: 10 }}>{ k }</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                            : 'loading'}
                    </div>
                    <div className='row'>
                        {props.data
                            ? props.data.Row_2.map((d, i) => (
                                <div key={`${d.name}-${i}`} className='col-md-4'>
                                    <div className='Price-item'>
                                        <h3 className='text-center'>{d.header}</h3>
                                        <p className='text-center' style={{ marginBottom: 10 }}>{ d.price }</p>
                                        {
                                            d.desc.map((k,j) => (
                                                <p className='text-center' style={{ marginBottom: 10 }}>{ k }</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                            : 'loading'}
                    </div>
{/* 
                    <div className='row' style={{ marginBottom: 50,marginTop: 30 }}>
                        <div className='col-md-12'>
                            <h4>Storno podmínky</h4>
                            <p>24 hodin předem - zdarma</p>
                            <p>Méně než 24 hodin - 500,- </p>
                        </div>
                    </div> */}

                    <div className='row' id="reservation" style={{ marginTop: 50 }}>
                        <h2>Zde se můžete objednat</h2>
                        <a href="https://iva-ripperova.reservio.com" target="_blank" className='btn btn-custom btn-lg page-scroll' style={{ marginTop: 30 }}> Rezervace </a>
                    </div>
                </div>
            </div>
        </div>
    )
}